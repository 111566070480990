import * as changeCase from "change-case";
import dayjs from 'dayjs';
import { createSlice } from "@reduxjs/toolkit";
import { dispatch } from "../store";
// utils
import axios from '../../utils/axios';

const initialState = {
    isLoading: false,
    error: null,
    sortBy: "newest",
    shipperCustomers: {}
}

const slice = createSlice({
    name: "shipperCustomers",
    initialState,
    reducers: {
      // START LOADING
      startLoading(state) {
        state.isLoading = true;
      },
      // STOP LOADING
      stopLoading(state) {
        state.isLoading = false;
      },
      // HAS ERROR
      hasError(state, action) {
        state.isLoading = false;
        state.error = action.payload;
      },
      getShipperCustomersSuccess(state, action) {
        state.isLoading = false;
        state.shipperCustomers = action.payload;
      }
    }
});

export const { getShipperCustomersSuccess, startLoading, stopLoading } = slice.actions;

// Reducer
export default slice.reducer;

export function getShipperCustomers({ shipperID, customerName, page, perPage }) {
    return async () => {
        dispatch(slice.actions.startLoading())
        try {
            let pathName = `/api/v1/customers/shipper_customers/${ shipperID }?page=${ page }&per_page=${ perPage }`
            if (customerName.trim()) {
                pathName = `${pathName}&search_val=${customerName}`;
            }

            // Execute the get request
            const response = await axios.get(pathName)
            // Reshape the response
            const customers = serialize(response.data)
            // Dispatch the response
            dispatch(slice.actions.getShipperCustomersSuccess(customers))
            dispatch(slice.actions.stopLoading())

            // return customers
        } catch (err) {
            console.error("Error: ", err)
            dispatch(slice.actions.hasError(err))
        }
    }
}

export function updateEditedShipperCustomer({ customers, updatedCustomer, status }){

    return () => {
        dispatch(slice.actions.startLoading())
        const newCustomerList = []

        if(status == "edit"){
            customers.data.forEach((customer) => {
              if(customer.id === updatedCustomer.id){
                newCustomerList.push(updatedCustomer)
              }else{
                newCustomerList.push(customer)
              }
            })
            dispatch(slice.actions.getShipperCustomersSuccess({ data: newCustomerList, pagination: customers.pagination }))
        }else{
            dispatch(slice.actions.getShipperCustomersSuccess({ data: [...customers.data, updatedCustomer], pagination: customers.pagination }))
        }

        dispatch(slice.actions.stopLoading())
    }
}



const serialize = (responseData) => {
  const total_count = responseData.meta.count
  const page = responseData.meta.page
  const limit = responseData.meta.limit

    return {data: responseData.data.map((r) => ({
        id: r?.attributes?.id,
        name: r?.attributes?.name,
        default: r?.attributes?.is_default,
        placeId: r?.attributes?.place?.data?.id,
        address: r?.attributes?.place?.data?.attributes?.address,
        latitude: r?.attributes?.place?.data?.attributes?.latitude,
        longitude: r?.attributes?.place?.data?.attributes?.longitude,
        town: r?.attributes?.place?.data?.attributes?.town?.data?.attributes?.name,
        isDefault: r?.attributes?.is_default,
        contacts: serializeContacts(r?.attributes?.contact_people?.data || []),
        createdAt: r?.attributes?.created_at
    })), pagination: { total: total_count, page: page, limit: limit }}
}

const serializeContacts = (data) => {
    const phone = []
    data.forEach((contact) => {
        phone.push({id: contact?.id, name: contact?.attributes?.name, phone: contact?.attributes?.phone_number})
    })

    return phone;
}