import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { Typography } from '@mui/material';
// import { makeStyles } from '@mui/styles';
import { Timeline, TimelineDot, TimelineItem, TimelineContent, 
    TimelineSeparator, TimelineConnector
} from '@mui/lab';
import TimelineOppositeContent, {
    timelineOppositeContentClasses,
} from '@mui/lab/TimelineOppositeContent';

// utils
import { fDate } from '../../../../utils/formatTime';


OrderTimelines.propTypes = {
    order: PropTypes.object
}

export default function OrderTimelines({ order, filterStatus }){

    const dateToDisplay = filterStatus === "delivered" ? 'Actual Delivery Date' : 'Expected Arrival Date' ; 
    const expectedDate = [
        { stepType:  "Pickup Date", dateTime: order?.pickupDate},
        { stepType: 'Expected Arrival Date', dateTime: order?.lpoDate  }
    ]

    const actualDate = [
        { stepType:  "Pickup Date", dateTime: order?.pickupDate},
        { stepType: 'Actual Delivery Date', dateTime: order?.actualDeliveryDate  }
    ]

    const dateArrayToDisplay = filterStatus === "delivered" ? actualDate : expectedDate;

    const timelines = useMemo(()=>(
        dateArrayToDisplay
    ), [order]);

    return(
        <Timeline
            sx={{
                [`& .${timelineOppositeContentClasses.root}`]: {
                    flex: 0.3,
                    textAlign: "left",
                    padding: '6px 6px' 
                }
            }}
        >
            {
                timelines.map((timeline, index)=>(
                    <TimelineItem key={index}>

                        <TimelineOppositeContent color="text.secondary">
                            { timeline.dateTime && fDate(timeline.dateTime)}
                        </TimelineOppositeContent>
                        
                        <TimelineSeparator>
                            <TimelineDot
                                color={ (timeline.stepType === 'Pickup Date' && 'warning') ||
                                    (timeline.stepType === dateToDisplay && 'primary') || 'primary'
                                }
                            />

                            { (index === (timelines.length - 1))? null: <TimelineConnector/> }
                        </TimelineSeparator>


                        <TimelineContent>

                            <Typography >
                                {timeline.stepType}
                            </Typography>

                        </TimelineContent>

                    </TimelineItem>
                ))
            }
        </Timeline>
    )
}
