// @mui
import { styled } from '@mui/material/styles';
import { Grid, Container, Typography , Link} from '@mui/material';
// components
import Page from '../components/Page';
import { FaqsHero, FaqsCategory, FaqsList, FaqsForm } from '../sections/faqs';
// ----------------------------------------------------------------------
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


const RootStyle = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(8),
  [theme.breakpoints.up('md')]: {
    paddingTop: theme.spacing(11),
  },
}));

const ResponsiveIframe = styled('div')({
  position: 'relative',
  paddingBottom: '56%',
  height: 0,
  overflow: 'hidden',
  maxWidth: '100%',
  background: '#000',
  marginBottom: '16px',
  '& iframe': {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    border: 0,
  },
});

export default function Faqs() {
  return (
    <Page title="Faqs">
      <RootStyle>
        {/* <FaqsHero /> */}
        <Container sx={{ mb: 10, position: 'relative' }}>
          <Typography variant="h3" sx={{ mb: 5, color: '#1D988A',
 }}>
            Frequently asked questions
          </Typography>

          {[
            {
              question: "I am having trouble logging in",
              videoId: "1AaF21i9NVtzdHQocVhRLVLc7V8EOU8T2",
            },
            {
              question: "What does the landing page include?",
              videoId: "1-Q9EM1NYEHXXW8NOarh0oYIeAOivd25W",
            },
            {
              question: "How do I Schedule a pickup request in the form view?",
              videoId: "1zx_bAP-ZIw63H5n0SIu2_ObaTuCcSyh_",
            },
            {
              question: "How do I Schedule a pickup request in the worksheet view?",
              videoId: "18G9BGrRZfSoOpSkcBPkvGlefGWRWnuZl",
            },
            {
              question: "How can I Schedule a return?",
              videoId: "1-7I-em_9pNFIxAxrLtHiPJmG3Vj5JRqK",
            },
            {
              question: "How can I track my orders?",
              videoId: "13z5Nd4qeLPokDCyCvJDRI_ujdB9erCRJ",
            },
            {
              question: "How can I track and manage my returns?",
              videoId: "1NL9UGAjr8meVo8ZOi5m5HsBWvnWKv5Di",
            },
            {
              question: "How can I view scheduled, ongoing and completed orders?",
              videoId: "1lONd_xuWvF66vwo4Zjtn3HbXFZLQsxl1",
            },
            {
              question: "Can I view orders I have scheduled in calendar view?",
              videoId: "1-SzCF5eWUJLZm8np0J5GlAYyh72Q2fe0",
            },
            {
              question: "Can I manage my delivery notes on this platform?",
              videoId: "1TBk-za1GKWJJcH-t_xZDD2xRBEyiPfYy",
            },
            {
              question: "Have you enabled any form of analytics?",
              videoId: "1pwUJfpS9T5TcYzC0frqat7DL5yjiR-15",
            },
            {
              question: "Can I add users and is there any way I can give feedback?",
              videoId: "1RRxqrkmcDJxXzZmknNUgq-oDuSG4YEJs",
            },
            {
              question: "How can I customize the app?",
              videoId: "1DYX_PZBUDjuAO8GY8-kTmJGF2SJy8aXL",
            },
          ].map((item, index) => (
            <Accordion key={index}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant sx={{ mb: 1 }}>
                  {item.question}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <ResponsiveIframe>
                  <iframe
                    src={`https://drive.google.com/file/d/${item.videoId}/preview`}
                    allow="autoplay"
                  ></iframe>
                </ResponsiveIframe>
              </AccordionDetails>
            </Accordion>
          ))}

          <Grid sx={{ mt: 7 }}>
            <Typography variant="h3">
              Didn't find what you were looking for?
              <Typography variant="h5">
                <Link href='https://senga.co/services/#faq'>
                  Click here for more information
                </Link>
              </Typography>
            </Typography>
          </Grid>
        </Container>
      </RootStyle>
    </Page>
  );
}