import PropTypes from 'prop-types';
import { deserialize } from "deserialize-json-api";
import { createContext, useEffect, useReducer, useCallback } from 'react';
// utils
import axios from '../utils/axios';
import { isValidToken, setSession } from '../utils/jwt';
// ----------------------------------------------------------------------
const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user, ...other } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
      ...other,
    };
  },
  LOGIN: (state, action) => {
    const { user, ...other } = action.payload;

    return {
      ...state,
      ...other,
      isAuthenticated: true,
      user,
    };
  },
  SET_RECENT_SHIPPER: (state, action) => {
    const { selectedShipper } = action.payload;
    return { ...state, user: { ...state.user, ...selectedShipper } };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
  REGISTER: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  UPDATE: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },

  UPDATE_INIT: (state, action) => {
    const newState = {
      ...state,
      user: {
        ...state.user,
        initPasswordChanged: action.payload.initPasswordChanged,
      },
    };
    return newState;
  },

  PASSWORD: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
};
const baseURL = process.env.REACT_APP_HOST_API;
const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const formatShipper = (shipper) => ({
  shipperId: shipper.id,
  shipperName: shipper.name,
  shipperNo: shipper.shipperNo,
  weightBased: shipper.weightBased,
  businessEntity: shipper.businessEntity,
  locationAddress: shipper.locationAddress,
  shipperLogo: shipper.shipperLogo,
  companyName: shipper.name,
  displayName: shipper.name,
  firstName: shipper.first_name,
  lastName: shipper.last_name,
});

const AuthContext = createContext({
  ...initialState,
  method: 'jwt',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  update: () => Promise.resolve(),
  updatePassword: () => Promise.resolve(),
});

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  // eslint-disable-next-line consistent-return
  const fetchRecentShipper = useCallback(async (headerConfig) => {
    try {
      const pathName = '/api/v1/recent_shipper_with_pickup';
      const response = await axios.get(pathName, headerConfig);
      const { data: shipper } = deserialize(response.data, { transformKeys: 'camelCase' });
      return shipper;
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = localStorage.getItem('accessToken');
        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);
          try {
            const config = { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${accessToken}` } };
            const userUrl = '/api/v1/user';
            const response = await axios.get(userUrl, config);
            const token = response?.data?.meta?.token || '';
            const { data } = deserialize(response.data, { transformKeys: 'camelCase' });
            const { id: userId, role, deactivated, userable,userableType,  roles, email, initPasswordChanged } = data;
            let shipper;
            const isAdmin = roles && roles.length >= 1 ? roles.find((role) => role.name === 'Admin') : role === 'admin'; // TODO: remove the old admin later on
            if (isAdmin) {
              shipper = await fetchRecentShipper();
            } else if (userable) {
              shipper = deserialize(data.userable, { transformKeys: 'camelCase' }).data;
            } else {
              console.log('*****No shipper set********');
            }
      
            const formattedShipper = formatShipper(shipper);
            const displayName = formattedShipper.shipperName;
            const user = {
              id: userId,
              photoURL: baseURL + formattedShipper.shipperLogo,
              email,
              displayName,
              companyName: formattedShipper.shipperName,
              role,
              roles,
              deactivated,
              initPasswordChanged,
              ...formattedShipper,
              token,
              firstName: data?.firstName,
              lastName: data?.lastName,
            };
            var weightBasedId
            weightBasedId = formattedShipper.shipperId.toString();
            // TODO remove this later when we define the weight based shippers in the database
            const weightBasedShippers = ['139', '145', '67', '165', '178']; // 165 = Loki
            const weightBased = !!weightBasedShippers.find(
              (weightBasedShipper) => weightBasedShipper.toString() === weightBasedId
            );
            if (weightBased) {
              user.weightBased = weightBased;
            }
            // TODO remove this later when we define the weight based shippers
            dispatch({ type: 'INITIALIZE', payload: { isAuthenticated: true, user, visitTimes: data.logInCount } });
            // if the initPasswordChanged false
            // direct the user to the change password page
          } catch (error) {
            console.error(error);
          }
        } else {
          dispatch({ type: 'INITIALIZE', payload: { isAuthenticated: false, user: null } });
        }
      } catch (err) {
        console.error(err);
        dispatch({ type: 'INITIALIZE', payload: { isAuthenticated: false, user: null } });
      }
    };
    initialize();
  }, [fetchRecentShipper]);

  const login = async (email, password) => {
    try {
      const loginUrl = '/users/login';
      const payload = { user: { email: email.trim(), password } };
      const response = await axios.post(loginUrl, payload);
      const { data } = deserialize(response.data, { transformKeys: 'camelCase' });
      const accessToken = response.headers.authorization.split(' ')[1];
      const token = response?.data?.meta?.token || '';
      const { id: userId, role, deactivated, userable, userableType, roles, initPasswordChanged } = data;
      let shipper;
      const isAdmin = roles && roles.length >= 1 ? roles.find((role) => role.name === 'Admin') : role === 'admin';

      // fetch for a shipper with a recent pickup request only if the login user is a Senga's admin/operational team
      setSession(accessToken);


      if (isAdmin) {
        shipper = await fetchRecentShipper();
      } else if (userable) {
        shipper = deserialize(data.userable, { transformKeys: 'camelCase' }).data;
      } else {
        console.log('********No shipper set');
        // throw new Error();
      }

      const formattedShipper = formatShipper(shipper);

      const displayName = formattedShipper.shipperName;
      const user = {
        id: userId,
        photoURL: baseURL + formattedShipper.shipperLogo,
        email,
        displayName,
        companyName: formattedShipper.shipperName,
        role,
        roles,
        deactivated,
        ...formattedShipper,
        initPasswordChanged,
        token,
        firstName: data?.firstName,
        lastName: data?.lastName,
      };

      // TODO remove this later when we define the weight based shippers in the database
      const weightBasedShippers = ['139', '145', '67', '165', '178']; // 165 = Loki
      var weightBasedId
      weightBasedId = formattedShipper.shipperId.toString();
      const weightBased = !!weightBasedShippers.find(
        (weightBasedShipper) => weightBasedShipper.toString() ===  weightBasedId
      );
      if (weightBased) {
        user.weightBased = weightBased;
      }
      dispatch({ type: 'INITIALIZE', payload: { isAuthenticated: true, user, visitTimes: data.logInCount } });
      return user;
    } catch (error) {
      console.log("Error: ", error);
      if (error.response) {
        // status code out of the range of 2xx
        if (error.response.status === 401) {
          throw new Error('Username or password invalid');
        }
        throw new Error('Something went wrong');
      } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request);
        throw new Error('Error accessing the authentication module');
      } else {
        console.log(error);
        // Error on setting up the request
        // console.log('Error', error.message);
        // throw new Error('Something went wrong');
        if (error.error) {
          throw new Error(error.error); // this is based on the senga v1 api which sends an error object
        } else {
          throw new Error('Something went wrong');
        }
      }
    }
  };

  const setSelectedShipper = (selectedShipper) => {
    dispatch({ type: 'SET_RECENT_SHIPPER', payload: { selectedShipper } });
  };

  const register = async (email, password, firstName, lastName) => {
    const response = await axios.post('/api/account/register', {
      email,
      password,
      firstName,
      lastName,
    });
    const { accessToken, user } = response.data;

    localStorage.setItem('accessToken', accessToken);

    dispatch({
      type: 'REGISTER',
      payload: {
        user,
      },
    });
  };

  const update = async (photoURL, email, displayName, phoneNumber) => {
    const accessToken = localStorage.getItem('accessToken');
    const user = {
      id: '324dfskhdfjdshfiu4e',
      photoURL: photoURL.preview,
      email,
      displayName,
      companyName: 'Tropical Heat',
      role: 'admin',
      phoneNumber,
    };

    setSession(accessToken);

    dispatch({
      type: 'UPDATE',
      payload: {
        user,
      },
    });
  };

  const updateUser = async ({ initPasswordChanged }) => {
    dispatch({
      type: 'UPDATE_INIT',
      payload: {
        initPasswordChanged,
      },
    });
  };

  const updatePassword = async (oldPassword, newPassword) => {
    try {
      const updatePasswordUrl = '/api/v1/user/change_password';

      const payload = { user: { new_password: newPassword, old_password: oldPassword } };

      const response = await axios.patch(updatePasswordUrl, payload);
      return response;
    } catch (error) {
      if (error?.data?.errors && error.data.errors.length > 0) {
        throw new Error(error.data.errors[0]);
      } else {
        throw new Error('Error: Bad Request error');
      }
    }
  };

  const logout = async () => {
    setSession(null);
    localStorage.removeItem("_retailer")
    dispatch({ type: 'LOGOUT' });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        logout,
        register,
        update,
        updatePassword,
        setSelectedShipper,
        formatShipper,
        updateUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };