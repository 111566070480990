import * as Yup from 'yup';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom'
import { Link as RouterLink } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Link, Stack, Alert, IconButton, InputAdornment } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// routes
import { PATH_AUTH, PATH_DASHBOARD } from '../../../routes/paths';
// hooks
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
// components
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFTextField } from '../../../components/hook-form';
import analytics from '../../../utils/analytics';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const { login } = useAuth();
  const navigate = useNavigate()
  const isMountedRef = useIsMountedRef();
  const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string().trim().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string().required('Password is required'),
  });

  const defaultValues = {
    email: '',
    password: '',
    remember: true,
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    reset,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    try {
      const user = await login(data.email, data.password);

      analytics.identify(data.email, {
        firstName: user?.first_name || '',
        lastName: user?.last_name || '',
        email: data.email,
        shipperId: user?.shipperId || '',
        shipperNo: user?.shipperNo || '',
        environment: process.env.NODE_ENV || '',
      });

      analytics.track('button-click-login', {
        buttonName: 'Login',
        page: 'login',
        email: data.email,
        firstName: user?.first_name || '',
        lastName: user?.last_name || '',
        environment: process.env.NODE_ENV || '',
      });
      
    } catch (error) {
      reset();

      if (isMountedRef.current) {
        // Regex to capitalize Email or Password
        const errorMessage =
          error.message === 'Invalid Email or password.'
            ? error.message.replace(/(e|p)/g, (m) => m.toUpperCase())
            : error.message;
        setError('afterSubmit', { ...error, message: errorMessage });
      }
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}

        <RHFTextField name="email" label="Email address" />

        <RHFTextField
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <Link component={RouterLink} variant="subtitle2" to={PATH_AUTH.resetPassword}>
          Forgot password?
        </Link>
      </Stack>

      <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
        Login
      </LoadingButton>
      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ ml: 15, my: 4 }}>
        <Link component={RouterLink} variant="subtitle2" to={PATH_AUTH.loginVideo}>
          Having trouble logging in?
        </Link>
      </Stack>

    </FormProvider>
  );
}
