// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';
// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  verify: path(ROOTS_AUTH, '/verify'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  loginVideo: path(ROOTS_AUTH, '/login-video'),
  newPassword: path(ROOTS_AUTH, '/new-password'),
  requestSent: path(ROOTS_AUTH, '/request-sent'),
};

export const PATH_PAGE = {
  home: '/home',
  shipperWelcome: '/shipper-welcome',
  acquisition: '/acquisition',
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
  guestOrdersDeliveryView: (token) => path(`/track-deliveries/${token}`),
  guestOrderDeliveryView: (token) => path(`/track-delivery/${token}`)
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  tms: {
    shippers: {
      onboard: path(ROOTS_DASHBOARD, '/shipper/new'),
      schedulePickup: (viewType) => path(ROOTS_DASHBOARD, `/schedule-pickup/${viewType}`),
      editSchedulePickup: (orderId) => path(ROOTS_DASHBOARD, `/schedule-pickup/${orderId}/edit`),
      packaging: path(ROOTS_DASHBOARD, '/packaging/new'),
      deliveryNotes: path(ROOTS_DASHBOARD, '/delivery-notes'),
      submittedRequests: path(ROOTS_AUTH, '/submittedRequests'),
      worksheet: (pickupDate, { reuse = false }) => path(ROOTS_DASHBOARD, `/worksheet/${pickupDate}?reuse=${reuse}`),
      pickupRequest: (view) => path(ROOTS_DASHBOARD, `/pickup-requests/${view}`),
    },
    customers: {
      list: path(ROOTS_DASHBOARD, '/customers/list')
    },
    dnote: {
      new: path(ROOTS_DASHBOARD, '/dnote/new'),
      view: (id) => path(ROOTS_DASHBOARD, `/dnote/${id}`),
      edit: (id) => path(ROOTS_DASHBOARD, `/dnote/${id}/edit`),
      generateDnote: (orderId) => path(ROOTS_DASHBOARD, `/generate-delivery-note/${orderId}`),
    },
    deliveries: {
      deliveryTracking: path(ROOTS_DASHBOARD, '/deliveries-tracking'),
      view: (id) => path(ROOTS_DASHBOARD, `/delivery-tracking/${id}`),
    },
    billingInfo: path(ROOTS_DASHBOARD, '/billing-info'),
    invoiceList: path(ROOTS_DASHBOARD, '/invoice-list'),
    invoicing: path(ROOTS_DASHBOARD, '/invoicing'),
    returns: {
      root: path(ROOTS_DASHBOARD, '/returns'),
      view: (id) => path(ROOTS_DASHBOARD, `/returns/${id}`),
      single_view: (id) => path(ROOTS_DASHBOARD, `/returns/${id}/view`),
      deliveries: path(ROOTS_DASHBOARD, '/returns/deliveries'),
      scheduleReturns: (orderId) => path(ROOTS_DASHBOARD, `/returns/schedule${!orderId ? '' : `?orderId=${orderId}`}`),
      editScheduledReturn: ({ returnDocRefId, orderId }) => (
        path(ROOTS_DASHBOARD, `/returns/edit?returnDocReferenceId=${returnDocRefId}&orderId=${orderId}`)
      ),
      waybills: path(ROOTS_DASHBOARD, '/returns/waybills'),
      items: path(ROOTS_DASHBOARD, '/returns/items'),
      note: (id) => path(ROOTS_DASHBOARD, `/returns/${id}/note`),
    },
    customerGraph: path(ROOTS_DASHBOARD, '/customer-graph'),
    feedback: path(ROOTS_DASHBOARD, '/feedback'),
    faqs: path(ROOTS_DASHBOARD, '/faqs'),
    modelInferencing: path(ROOTS_DASHBOARD, '/model-inferencing'),
    pickupSchedule: path(ROOTS_DASHBOARD, '/pickup-schedule'),
  },
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
    ecommerce: path(ROOTS_DASHBOARD, '/ecommerce'),
    analytics: path(ROOTS_DASHBOARD, '/analytics'),
    banking: path(ROOTS_DASHBOARD, '/banking'),
    booking: path(ROOTS_DASHBOARD, '/booking'),
  },
  mail: {
    root: path(ROOTS_DASHBOARD, '/mail'),
    all: path(ROOTS_DASHBOARD, '/mail/all'),
  },
  chat: {
    root: path(ROOTS_DASHBOARD, '/chat'),
    new: path(ROOTS_DASHBOARD, '/chat/new'),
    view: (name) => path(ROOTS_DASHBOARD, `/chat/${name}`),
  },
  calendar: path(ROOTS_DASHBOARD, '/calendar'),
  kanban: path(ROOTS_DASHBOARD, '/kanban'),
  permissionDenied: path(ROOTS_DASHBOARD, '/permission-denied'),
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    new: path(ROOTS_DASHBOARD, '/user/new'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    account: path(ROOTS_DASHBOARD, '/user/account'),
    settings: path(ROOTS_DASHBOARD, '/user/settings'),
    edit: (id) => path(ROOTS_DASHBOARD, `/user/${id}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
  },
  eCommerce: {
    root: path(ROOTS_DASHBOARD, '/e-commerce'),
    shop: path(ROOTS_DASHBOARD, '/e-commerce/shop'),
    list: path(ROOTS_DASHBOARD, '/e-commerce/list'),
    checkout: path(ROOTS_DASHBOARD, '/e-commerce/checkout'),
    new: path(ROOTS_DASHBOARD, '/e-commerce/product/new'),
    view: (name) => path(ROOTS_DASHBOARD, `/e-commerce/product/${name}`),
    edit: (name) => path(ROOTS_DASHBOARD, `/e-commerce/product/${name}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-blazer-low-77-vintage/edit'),
    demoView: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-air-force-1-ndestrukt'),
  },
  invoice: {
    root: path(ROOTS_DASHBOARD, '/invoice'),
    list: path(ROOTS_DASHBOARD, '/invoice/list'),
    new: path(ROOTS_DASHBOARD, '/invoice/new'),
    view: (id) => path(ROOTS_DASHBOARD, `/invoice/${id}`),
    edit: (id) => path(ROOTS_DASHBOARD, `/invoice/${id}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1/edit'),
    demoView: path(ROOTS_DASHBOARD, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5'),
  },
  blog: {
    root: path(ROOTS_DASHBOARD, '/blog'),
    posts: path(ROOTS_DASHBOARD, '/blog/posts'),
    new: path(ROOTS_DASHBOARD, '/blog/new'),
    view: (title) => path(ROOTS_DASHBOARD, `/blog/post/${title}`),
    demoView: path(ROOTS_DASHBOARD, '/blog/post/apply-these-7-secret-techniques-to-improve-event'),
  },
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
